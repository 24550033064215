import React, {useState, useEffect} from 'react';
import axios from 'axios'

// markup
const IndexPage = () => {

    const [currentTime, setCurrentTime] = useState(null);
    const [postMessage, setPostMessage] = useState(null);


    const [errorGetMessage, setErrorGetMessage] = useState(null);
    const [errorPostMessage, setErrorPostMessage] = useState(null);

    const getRequest = async () => {
        const res = await axios.get("https://wm0g164ff2.execute-api.eu-west-2.amazonaws.com/api/")
            .then(res => {
                setCurrentTime(res.data.current_time);
            }).catch(error => {
                console.log(">> error: " + error);

                setErrorGetMessage(`Get Error: ${error}`);
            })
    }

    const postRequest = async () => {
        const res = await axios.post("https://wm0g164ff2.execute-api.eu-west-2.amazonaws.com/api/echo-test", {
            payload: 'Test payload'
        })
            .then(res => {
                console.log(res.data);
                setPostMessage(res.data.message);
            }).catch(error => {
                console.log(">> error: " + error);

                setErrorPostMessage(`Post Error: ${error}`);
            })

    }

    useEffect(() => {
        getRequest();
    }, []);


    return (
        <main>

            <h1>CORS Test</h1>

            <p>This page aims to make sure the CORS would work<br />Only supposed to work at reality.pulsely.com</p>

            <hr/>

            <h2>
                GET Test
            </h2>

            <p>{errorGetMessage}</p>
            <p>This is the result of the GET test: </p>
            {currentTime ? (
                <p>{currentTime}</p>

            ) : null}

            <hr/>

            <h2>
                POST Test
            </h2>
            <p>{errorPostMessage}</p>
            <p>Click this button to trigger POST request.</p>

            <button onClick={postRequest}>POST request</button>

            {postMessage ? (
                <div>
                    <p>This is the result of the POST test:</p>
                    <p>{postMessage}</p>
                </div>
            ) : null}


        </main>
    )
}

export default IndexPage
